import { createAction } from "redux-actions";

import ISite from "models/ISite";
import { SET_SITE, RESET } from "./constants/site";

export interface ISetSite {
    name?: string;
    site: ISite;
}

export const setSite = createAction<ISetSite>(SET_SITE);
export const resetSite = createAction(RESET);
