import * as React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

import Paper from "components/paper";
import { getLoading } from "./Loader.reducer";
import useStyles from "./Loader.styles";

export const LoaderComponent: React.FC = () => {
    const classes = useStyles();
    return (
        <div className={classes.loader}>
            <span className={clsx(classes.dot, classes.dot_1)} />
            <span className={clsx(classes.dot, classes.dot_2)} />
            <span className={clsx(classes.dot, classes.dot_3)} />
            <span className={clsx(classes.dot, classes.dot_4)} />
        </div>
    );
};

const boxStyles = {
    display: "flex",
    minHeight: "150px",
    minWidth: "150px",
};

export const BoxedLoader = () => (
    <Paper style={boxStyles} elevation={0}>
        <LoaderComponent />
    </Paper>
);

const useLoaderStyles = makeStyles(() => ({
    root: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "0",
        display: "flex",
        zIndex: 10,
        alignItems: "center",
        margin: (props: any) => props.margin,
    },
    hoverImage: {
        backgroundColor: "gainsboro",
        opacity: 0.5,
    },
}));

export const TransparentLoader: React.FC<{ loading: boolean; margin?: string }> = ({ loading, margin }) => {
    const styles = useLoaderStyles({ margin });
    return loading ? (
        <>
            <div className={clsx(styles.root, styles.hoverImage)} />
            <div className={styles.root}>
                <LoaderComponent />
            </div>
        </>
    ) : null;
};

const Loader: React.FC<{ margin?: string }> = ({ margin = "0 -15px" }) => {
    const loading = useSelector(getLoading);

    return <TransparentLoader loading={loading} margin={margin} />;
};

export default Loader;
