import * as React from "react";
import { Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";

import { BoxedLoader } from "components/loader";
import AppContainer from "components/app-container";

const Custom = lazy(() => import("pages/custom"));
const Home = lazy(() => import("pages/home"));
const QuickPay = lazy(() => import("pages/quickpay"));
const Payment = lazy(() => import("pages/payment"));
const Error = lazy(() => import("pages/error"));
const NotFound = lazy(() => import("pages/not-found"));
const Processing = lazy(() => import("pages/processing"));
const Receipt = lazy(() => import("pages/receipt"));

const App: React.FC = () => (
    <AppContainer>
        <Suspense fallback={<BoxedLoader />}>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/payment" component={Payment} />
                <Route exact path="/quickpay/:id" component={QuickPay} />
                <Route exact path={["/privacy-policy", "/terms-and-conditions"]} component={Custom} />
                <Route exact path="/processing" component={Processing} />
                <Route exact path="/receipt" component={Receipt} />
                <Route exact path="/error" component={Error} />
                <Route path="*" component={NotFound} />
            </Switch>
        </Suspense>
    </AppContainer>
);

export default App;
