import * as React from "react";
import { Link } from "react-router-dom";
import { Theme, makeStyles } from "@material-ui/core";
import Paper from "components/paper";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        padding: "5px",
    },
    logoImg: {
        width: "220px",
        height: "80px",
        backgroundImage: theme.logoUrl,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    },
}));

const Header: React.FC = ({ children }) => {
    const styles = useStyles();
    return (
        <Paper className={styles.root}>
            <Link to="/">
                <div className={styles.logoImg} />
            </Link>
            {children}
        </Paper>
    );
};

export default Header;
