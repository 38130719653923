import { createSelector } from "reselect";
import { handleActions, Action } from "redux-actions";

import { IAppState } from "state";
import ISite from "models/ISite";
import { SET_SITE, RESET } from "actions/constants/site";
import { ISetSite } from "actions/site";

export const site = handleActions<Record<string, ISite | null>, any>(
    {
        [SET_SITE]: (state, { payload: { name, site: siteObj } }: Action<ISetSite>) => ({
            ...state,
            [name || "main"]: siteObj,
        }),
        [RESET]: (state, { payload: name }) => ({ ...state, [name || "main"]: null }),
    },
    {}
);

export const getSite = (state: IAppState) => {
    return state.site.main;
};

export const getNamedSite = (name: string) => (state: IAppState) => state.site[name];

export const getHomePageState = createSelector(getSite, (siteState) => {
    let footer;
    let header;
    if (siteState.landingPageHtml && siteState.landingPageHtml.indexOf("%payment%") > -1) {
        const [h, f] = siteState.landingPageHtml.split("%payment%");
        footer = f;
        header = h;
    }
    return {
        brandName: siteState.externalSiteName,
        footer,
        header,
    };
});
