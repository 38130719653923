import { createBrowserHistory } from "history";
import { trimEndSlash } from "utils/stringHelpers";
import ISite from "../models/ISite";
import { IOrganisation } from "../models/IOrganisation";

const windowObj = window as any;
const withUrl = (windowObj.site as ISite) || (windowObj.org as IOrganisation);
const urlPathPrefix = trimEndSlash(withUrl.urlPathPrefix);

// We set the basename so that nested sites correctly route in react.
// API calls base path is handled within the index setup.
export default createBrowserHistory({ basename: urlPathPrefix });
