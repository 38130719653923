import { handleActions } from "redux-actions";
import { IAppState } from "state";
import { SET_LOADING } from "./Loader.constants";

const loading = handleActions<boolean, boolean>(
    {
        [SET_LOADING]: (_, action) => action.payload,
    },
    false
);

export const getLoading = (state: IAppState) => state.loading;

export default loading;
