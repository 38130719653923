import * as React from "react";
import { makeStyles, PaperProps, Paper as MuiPaper } from "@material-ui/core";

interface IProps extends PaperProps {
    padding?: React.CSSProperties["padding"];
    minHeight?: React.CSSProperties["minHeight"];
    position?: React.CSSProperties["position"];
}

const useStyles = makeStyles(() => ({
    root: {
        padding: ({ padding = "15px" }: IProps) => padding,
        minHeight: ({ minHeight = 0 }: IProps) => minHeight,
        position: ({ position = "inherit" }: IProps) => position,
    },
    rounded: {
        borderRadius: 0,
    },
}));

const Paper: React.FC<IProps> = ({ padding, minHeight, position, ...rest }) => {
    const classes = useStyles({ padding, minHeight, position });
    return <MuiPaper {...rest} classes={classes} />;
};

export default Paper;
