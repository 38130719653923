/* eslint-disable no-console */
import { ILogger } from "./ILogger";

export default class ClientLogger implements ILogger {
    debug(...data: any[]): void {
        console.debug(data[0], ...data.slice(1));
    }

    info(...data: any[]): void {
        console.info(data[0], ...data.slice(1));
    }

    warn(...data: any[]): void {
        console.warn(data[0], ...data.slice(1));
    }

    error(...data: any[]): void {
        console.error(data[0], ...data.slice(1));
    }
}
