import { ILogger } from "./ILogger";

export default class Logger {
    private static initialized = false;

    private static implementation: ILogger;

    static initLogger(implementation: ILogger) {
        if (this.implementation) {
            throw new Error("Logger can not be initialized more than once!");
        }
        this.implementation = implementation;
        this.initialized = true;
    }

    static get isInitialized(): boolean {
        return this.initialized;
    }

    static debug(...args: any[]): void {
        return this.implementation.debug(...args);
    }

    static info(...args: any[]): void {
        return this.implementation.info(...args);
    }

    static warn(...args: any[]): void {
        return this.implementation.warn(...args);
    }

    static error(...args: any[]): void {
        return this.implementation.error(...args);
    }
}
