import * as React from "react";
import { useRef, useEffect } from "react";
import { OutlinedInput, OutlinedInputProps, FormControl, FormHelperText, makeStyles, Theme } from "@material-ui/core";
import intlTelInput, { Plugin } from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import { FieldRenderProps, FieldProps, Field, FieldInputProps } from "react-final-form";
import { ShowErrorFunc, showErrorOnChange } from "mui-rff";
import "./PhoneNumber.module.scss";

export type TextFieldProps = Partial<Omit<OutlinedInputProps, "type" | "onChange">> & {
    name: string;
    fieldProps?: Partial<FieldProps<any, any>>;
    showError?: ShowErrorFunc;
    initialCountry?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
    phone: ({ required }: { required: boolean }) => ({
        "& input::placeholder": {
            color: required ? theme.palette.warning.main : undefined,
            opacity: required ? 1 : 0.54,
        },
        "&.Mui-error input::placeholder": {
            color: theme.palette.error.main,
            opacity: 1,
        },
    }),
}));

function PhoneNumberForm(props: TextFieldProps) {
    const { name, fieldProps, ...rest } = props;

    return (
        <Field
            name={name}
            render={({ input, meta }) => <PhoneNumber input={input} meta={meta} name={name} {...rest} />}
            {...fieldProps}
        />
    );
}

export interface Phone {
    phoneNumber: string;
    dialCode: string;
}

interface IProps extends FieldRenderProps<Phone, HTMLElement> {
    input: FieldInputProps<Phone>;
}

export const PhoneNumber: React.FC<IProps> = ({
    input: { name, value, onChange, onBlur, onFocus },
    meta,
    disabled,
    fullWidth,
    helperText,
    required,
    inputProps,
    initialCountry = "gb",
    showError = showErrorOnChange,
    ...rest
}) => {
    const input = useRef<HTMLInputElement | null>(null);
    const phoneRef = useRef<Plugin | null>(null);

    const onChangeHandler = () => {
        const phone = phoneRef.current!;
        const prevValue: string = phone.prevValue;
        const phoneNumber = phone.getNumber();
        if (prevValue !== phoneNumber) {
            onChange({
                target: {
                    name,
                    value: {
                        phoneNumber,
                        dialCode: phone.getSelectedCountryData().dialCode,
                    },
                },
            });
            phone.prevValue = phoneNumber;
        }
    };

    useEffect(() => {
        phoneRef.current = intlTelInput(input.current!, {
            separateDialCode: true,
            initialCountry,
            preferredCountries: [initialCountry],
        });
        phoneRef.current.prevValue = value?.phoneNumber ?? "";

        import("intl-tel-input/build/js/utils");

        const inputCurrent = input.current!;
        inputCurrent.addEventListener("countrychange", onChangeHandler);
        return () => {
            inputCurrent?.removeEventListener("countrychange", onChangeHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        phoneRef.current!.setNumber(value?.phoneNumber ?? "");
    }, [value]);

    const { error, submitError } = meta;
    const isError = showError({ meta });
    const errorText = isError ? error || submitError : helperText;

    const styles = useStyles({ required });
    return (
        <FormControl variant="outlined" error={isError} disabled={disabled} required={required} fullWidth={fullWidth}>
            <OutlinedInput
                className={styles.phone}
                onChange={onChangeHandler}
                onBlur={onBlur}
                onFocus={onFocus}
                inputRef={input}
                inputProps={{ ...inputProps, required }}
                {...rest}
                placeholder={`Phone Number${required ? " *" : ""}`}
            />
            {errorText && <FormHelperText>{errorText}</FormHelperText>}
        </FormControl>
    );
};

export default PhoneNumberForm;
