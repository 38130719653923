import { createMuiTheme, getContrastRatio, fade, lighten, darken } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { IThemeSettings } from "./IThemeSettings";

declare module "@material-ui/core/styles/createMuiTheme" {
    interface Theme {
        logoUrl: string;
    }
    interface ThemeOptions {
        logoUrl: string;
    }
}

declare module "@material-ui/core/styles/createPalette" {
    interface Palette {
        labelBg: React.CSSProperties["color"];
        link: React.CSSProperties["color"];
        linkHover: React.CSSProperties["color"];
        navLinkHover: React.CSSProperties["color"];
        navLinkHoverBg: React.CSSProperties["color"];
    }
    interface PaletteOptions {
        labelBg: React.CSSProperties["color"];
        link: React.CSSProperties["color"];
        linkHover: React.CSSProperties["color"];
        navLinkHover: React.CSSProperties["color"];
        navLinkHoverBg: React.CSSProperties["color"];
    }
}

export default (settings: IThemeSettings) => {
    const ratioLight = getContrastRatio(settings.colorPaper, "#000");
    const ratioDark = getContrastRatio(settings.colorPaper, "#fff");
    const isDark = ratioDark > ratioLight;
    const theme = createMuiTheme({
        logoUrl: settings.logoUrl,
        palette: {
            type: isDark ? "dark" : "light",
            primary: { main: settings.colorPrimary },
            secondary: green,
            background: {
                default: settings.colorBackground,
                paper: settings.colorPaper,
            },
            success: settings.colorSuccess ? { main: settings.colorSuccess } : undefined,
            error: settings.colorDanger ? { main: settings.colorDanger } : undefined,
            warning: settings.colorWarning ? { main: settings.colorWarning } : undefined,
            info: settings.colorInfo ? { main: settings.colorInfo } : { main: "#5cb85c" },
            divider: settings.colorDivider,
            navLinkHover: settings.colorLinkNavHover,
            navLinkHoverBg: settings.colorLinkNavHoverBg,
            labelBg: settings.colorLabelBg,
            link: settings.colorLink,
            linkHover: settings.colorLinkHover,
        },
        typography: {
            fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
            fontWeightBold: 600,
            h1: {
                fontSize: "36px",
                marginBottom: "10px",
                marginTop: "20px",
            },
            h2: {
                fontSize: "30px",
            },
            h3: {
                backgroundColor: settings.colorLabelBg,
                padding: "5px 15px",
                margin: "15px 0 15px -15px",
                display: "inline-flex",
                fontSize: "24px",
            },
            h4: {
                fontSize: "18px",
                marginTop: "10px",
                marginBottom: "10px",
            },
            h6: {
                fontSize: "12px",
            },
            body1: {
                fontSize: "14px",
            },
        },
        props: {
            MuiTextField: {
                size: "small",
                variant: "outlined",
            },
            MuiFormControl: {
                size: "small",
                variant: "outlined",
            },
        },
        overrides: {},
    });

    theme.overrides = {
        ...theme.overrides,
        MuiCssBaseline: {
            "@global": {
                img: {
                    display: "block",
                },
                p: {
                    margin: "0 0 10px",
                },
                ul: {
                    marginTop: 0,
                    marginBottom: "10px",
                },
                th: {
                    fontWeight: "600 !important",
                },
                "a, a.MuiTypography-colorPrimary": {
                    cursor: "pointer",
                    color: settings.colorLink,
                    "&:hover, &:focus": {
                        color: settings.colorLinkHover,
                    },
                },
                ".nobold": {
                    fontWeight: "normal !important" as any,
                },
            },
        },
        MuiFormLabel: {
            root: {
                "&.Mui-required:not(.Mui-focused):not(.Mui-error)": {
                    color: theme.palette.warning.main,
                },
            },
        },
        MuiDivider: {
            root: {
                height: "4px",
            },
        },
        MuiTableCell: {
            root: {
                padding: "5px",
                borderBottom: `1px solid ${
                    theme.palette.type === "light"
                        ? lighten(fade(theme.palette.text.primary, 1), 0.88)
                        : darken(fade(theme.palette.text.primary, 1), 0.68)
                }`,
            },
            sizeSmall: {
                padding: "6px 8px 6px 8px",
            },
        },
        MuiInputLabel: {
            root: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                right: "22px",
                bottom: 0,
            },
            shrink: {
                right: "unset",
            },
        },
        MuiContainer: {
            maxWidthLg: {
                maxWidth: "1500px !important",
            },
        },
        MuiInputBase: {
            inputMultiline: {
                overflow: "auto",
            },
        },
        MuiButtonBase: {
            root: {
                "&.Mui-disabled": {
                    pointerEvents: "all",
                },
            },
        },
    };

    return theme;
};
