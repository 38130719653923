import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
    loader: {
        position: "relative",
        width: "60px",
        height: "12px",
        margin: "16px auto",
    },
    dot: {
        display: "inline-block",
        width: "12px",
        height: "12px",
        borderRadius: "6px",
        background: "#ccc",
        position: "absolute",
    },
    dot_1: {
        animation: "$animateDot1 1.5s linear infinite",
        left: "16px",
        background: "#e579b8",
    },
    dot_2: {
        animation: "$animateDot2 1.5s linear infinite",
        animationDelay: "0.5s",
        left: "32px",
    },
    dot_3: {
        animation: "$animateDot3 1.5s linear infinite",
        left: "16px",
    },
    dot_4: {
        animation: "$animateDot4 1.5s linear infinite",
        animationDelay: "0.5s",
        left: "32px",
    },
    "@keyframes animateDot1": {
        "0%": {
            transform: "rotate(0deg) translateX(-16px)",
        },
        "25%": {
            transform: "rotate(180deg) translateX(-16px)",
        },
        "75%": {
            transform: "rotate(180deg) translateX(-16px)",
        },
        "100%": {
            transform: "rotate(360deg) translateX(-16px)",
        },
    },
    "@keyframes animateDot2": {
        "0%": {
            transform: "rotate(0deg) translateX(-16px)",
        },
        "25%": {
            transform: "rotate(-180deg) translateX(-16px)",
        },
        "75%": {
            transform: "rotate(-180deg) translateX(-16px)",
        },
        "100%": {
            transform: "rotate(-360deg) translateX(-16px)",
        },
    },
    "@keyframes animateDot3": {
        "0%": {
            transform: "rotate(0deg) translateX(16px)",
        },
        "25%": {
            transform: "rotate(180deg) translateX(16px)",
        },
        "75%": {
            transform: "rotate(180deg) translateX(16px)",
        },
        "100%": {
            transform: "rotate(360deg) translateX(16px)",
        },
    },
    "@keyframes animateDot4": {
        "0%": {
            transform: "rotate(0deg) translateX(16px)",
        },
        "25%": {
            transform: "rotate(-180deg) translateX(16px)",
        },
        "75%": {
            transform: "rotate(-180deg) translateX(16px)",
        },
        "100%": {
            transform: "rotate(-360deg) translateX(16px)",
        },
    },
}));
