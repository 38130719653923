import { handleActions, Action } from "redux-actions";
import { SET_ERROR, RESET_ERROR } from "actions/constants/error";
import { IAppState } from "state";

export const error = handleActions<Error | null, any>(
    {
        [SET_ERROR]: (_, { payload }: Action<Error>) => {
            return payload;
        },

        [RESET_ERROR]: () => null,
    },

    null
);

export const getError = (state: IAppState) => state.error;
