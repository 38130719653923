import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import * as React from "react";
import { render } from "react-dom";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import axios from "axios";

import { trimEndSlash } from "utils/stringHelpers";
import Logger from "utils/log/Logger";
import ClientLogger from "utils/log/ClientLogger";
import history from "utils/history";
import getTheme from "styles/theme";
import { IThemeSettings } from "styles/IThemeSettings";
import createStore from "store";
import ISite from "models/ISite";

import App from "./App";

Logger.initLogger(new ClientLogger());

const site = (window as any).site as ISite;
const themeSettings = (window as any).theme as IThemeSettings;

// We set the base path so that nested sites correctly call the API methods.
// React routing base path is handled within the history.
axios.defaults.baseURL = trimEndSlash(site.urlPathPrefix);

const store = createStore({ site: { init: { main: site } } }, []);
const theme = getTheme(themeSettings);

render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <Router history={history}>
                <CssBaseline>
                    <App />
                </CssBaseline>
            </Router>
        </Provider>
    </ThemeProvider>,
    document.getElementById("root")
);
