import { Reducer, ReducersMapObject, combineReducers } from "redux";
import loading from "components/loader/Loader.reducer";
import { error } from "./error";
import { site } from "./site";

export default (asyncReducers?: ReducersMapObject): Reducer =>
    combineReducers({
        site,
        error,
        loading,
        ...asyncReducers,
    }) as any;
