import * as React from "react";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

import { getSite } from "reducers/site";
import Header from "components/header";
import Footer from "./components/footer";

const AppContainer: React.FC = ({ children }) => {
    const site = useSelector(getSite);

    return (
        <Container maxWidth="md">
            <Helmet titleTemplate={`%s - ${site.externalSiteName}`} defaultTitle={site.externalSiteName} />
            <Header />

            <Divider />
            {children}
            <Divider />

            <Footer site={site} />
        </Container>
    );
};

export default AppContainer;
