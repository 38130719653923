import { applyMiddleware, compose, createStore, Store, ReducersMapObject } from "redux";
import createSagaMiddleware, { SagaMiddleware, Saga } from "redux-saga";
import createReduxPromiseListener from "redux-promise-listener";
import { ReduxCompatibleReducer } from "redux-actions";

import StoreRegister from "services/global/StoreRegister";
import createReducer from "./reducers";
import { IAppState } from "./state";

const composeEnhancers =
    process.env.NODE_ENV !== "production" &&
    typeof window !== "undefined" &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose;

const sagaMiddleware = createSagaMiddleware();
export const promiseListener = createReduxPromiseListener();
export type AppStore = Store<IAppState> & {
    sagaMiddleware: SagaMiddleware<object>;
    asyncReducers: ReducersMapObject;
    sagas: string[];
};

interface IInitReducers {
    [key: string]: {
        reducer?: ReduxCompatibleReducer<any, any>;
        init?: any;
    };
}

export default (reducers: IInitReducers, sagas: Saga[]): AppStore => {
    const initState: Partial<IAppState> = {
        loading: false,
        ...Object.entries(reducers).reduce((prev: any, [name, value]) => {
            // eslint-disable-next-line no-param-reassign
            prev[name] = value.init;
            return prev;
        }, {}),
    };
    const initReducers = Object.entries(reducers)
        .filter(([, value]) => !!value.reducer)
        .reduce((prev: any, [name, value]) => {
            // eslint-disable-next-line no-param-reassign
            prev[name] = value.reducer;
            return prev;
        }, {});

    const store: AppStore = createStore(
        createReducer(initReducers),
        initState,
        composeEnhancers(applyMiddleware(sagaMiddleware, promiseListener.middleware))
    );

    store.sagaMiddleware = sagaMiddleware;
    sagas.forEach((saga) => sagaMiddleware.run(saga));
    store.asyncReducers = initReducers;
    store.sagas = [];

    StoreRegister.setStore(store);
    return store;
};
