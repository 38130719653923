import { Phone } from "components/phone-number/PhoneNumber";

export enum FieldVisibility {
    None = 0,
    CustomerOnly = 1,
    AgentOnly = 2,
    Both = 3,
}

export enum MerchantAccountType {
    DataCash = 4,
}

export enum PaymentEnvironment {
    Test = 0,
    Live = 1,
}

export enum QuickPayMode {
    Disabled,
    ViaEmail,
    ViaSMS,
}

export enum VerificationField {
    Postcode,
    PayeeCustom1,
    PayeeCustom2,
    PayeeCustom3,
    DateOfBirth,
    AccountHolderSurnameOrCompanyName,
    AccountNumber,
    Email,
    PhoneNumber,
}

export interface IMerchantAccount {
    id: number;
    isEnabled: boolean;
    priority: number;
    type: MerchantAccountType;
    currencyCode: string;
    supportsEcom: boolean;
    supportsMoto: boolean;
    supportsRefunds: boolean;
    supportsRecurring: boolean;
    merchantId: string;
}

export interface IAccountDetailsField {
    propertyName: string;
    displayName: string;
    helpText: string;
}

export interface IAccountDetails {
    primaryReference: IAccountDetailsField;
    customFields: IAccountDetailsField[];
}

export interface ISiteDataSimple {
    id: string;
    externalSiteName: string;
    internalSiteName: string;
    currencyPrefix: string;

    payeeCustom1Label?: string;
    payeeCustom2Label?: string;
    payeeCustom3Label?: string;

    accountDetails: {
        primaryReference: {
            displayName: string;
        };
    };
}

export default interface ISite {
    id: string; // This will be a GUID
    active: boolean;
    externalSiteName: string; // in the footer, example "Dev" name
    externalOrgName: string; // on "Payment" page next to "submit payment", example "Bluestone"
    internalSiteName: string; // on Phone payment page internalName
    url: string;
    urlPathPrefix: string;
    landingPageHtml?: string;
    privacyPolicyExists: boolean;
    termsConditionsExists: boolean;
    footerHtml?: string;
    currencyPrefix: string;
    currencyCode: string;

    payeeCustom1Label?: string;
    payeeCustom2Label?: string;
    payeeCustom3Label?: string;
    maxmimumTransactionValue?: number;

    isMCC6012Compliant: boolean;

    postcodeRequired: boolean;

    dateOfBirthVisibility: FieldVisibility;
    dateOfBirthRequired: boolean;

    notesVisibility: FieldVisibility;
    notesRequired: boolean;

    emailVisibility: FieldVisibility;
    emailRequired: FieldVisibility;

    payeeCustom1Visibility: FieldVisibility;
    payeeCustom1Required: FieldVisibility;

    payeeCustom2Visibility: FieldVisibility;
    payeeCustom2Required: FieldVisibility;

    payeeCustom3Visibility: FieldVisibility;
    payeeCustom3Required: FieldVisibility;
    merchantEnvironment?: PaymentEnvironment;
    acceptedCards?: string[];

    accountDetails: IAccountDetails;
    accountInfoEnabled?: boolean;
    supportsRecurringPayments: boolean;
    quickPayMode: QuickPayMode;
    senderId: string;
    dialCodeCountry: string;
    verificationFields: IVerificationFieldModel;

    doNotSendEmailsToPayee: boolean;
    sitePhoneNumber?: string;
    phoneNumber?: Phone;
}

export interface IVerificationFieldModel {
    verificationFields: VerificationField[];
    customVerificationFields: string[];
}
