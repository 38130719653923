/* eslint-disable react/no-danger */
import * as React from "react";
import { makeStyles } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import Paper from "components/paper";
import ISite from "models/ISite";

const useStyles = makeStyles(() => ({
    footerLinks: {
        marginTop: "10px",
    },
}));

interface IProps {
    site: ISite;
}

const Footer: React.FC<IProps> = ({ site }) => {
    const styles = useStyles();
    const links = [];
    if (site.termsConditionsExists) {
        links.push(
            <NavLink key="termsAndConditions" to="/terms-and-conditions">
                Terms and Conditions
            </NavLink>
        );
    }

    if (site.privacyPolicyExists) {
        links.push(
            <NavLink key="privacyPolicy" to="/privacy-policy">
                Privacy Policy
            </NavLink>
        );
    }

    const year = new Date().getFullYear().toString();
    const footer = site.footerHtml ? (
        <div dangerouslySetInnerHTML={{ __html: site.footerHtml.replace("%year%", year) }} />
    ) : (
        <div>
            {year} &copy; {site.externalSiteName}
        </div>
    );

    return (
        <Paper>
            {footer}
            {!!links.length && (
                <div className={styles.footerLinks}>
                    {links.reduce<Array<any>>((r, a, i) => r.concat(i === 0 ? undefined : " | ", a), [])}
                </div>
            )}
        </Paper>
    );
};

export default Footer;
